.author-image {
    .img {
        width: 100px;
        height: 100px;
        display: block;
        border-radius: 50%;
        margin: auto;
        background-size: cover;
        background-position: center;
    }
}

.single-post {
    .post-title, .post-meta{
        max-width: 700px;
        width: 100%;
        margin: auto;
    }

    .post-content img {
        width: 100%;
        margin: auto;
    }

    .post-meta {
        margin: 20px auto 0;
    }

    .post-content {
        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }

    .post-title {
        font-size: 5rem;
    }

    .large {
        img {
            max-width: none;
        }
    }

    figure {
        position: relative;
        margin-bottom: 25px;

        &.large {
            max-width: none;
            width: auto;

            img {
                width: 100%;
            }
        }

        &.left {
            float: left;
            margin: 20px 20px 20px 0;
        }

        &.right {
            float: right;
            margin: 20px 0 20px 20px;
        }

        p {
            margin: 0; /* jekyll generates paragraphs in figure elements */
        }

        img {
            display: block;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: none;
        }
    }

    figcaption {
        text-align: center;
        font-style: italic;
        padding-top: 10px;
        line-height: 1.5;
        color: #999;
    }

    img[src*='#right'], img[src*='#left'] {
        width: auto;
        height: auto;
        max-width: 50%;
        max-height: 300px;
        margin: 1em;
    }

    img[src*='#right'] {
        float: right;
        clear: right;
        margin-right: 0;
    }

    img[src*='#left'] {
        float: left;
        clear: left;
        margin-left: 0;
    }

    img[src*='#border'] {
        border: 2px solid #77C6E0aa;
        border-radius: 4px;
    }

    .hint {
        padding: 12px 24px 12px 30px;
        margin: 2em 0;
        background-color: #f8f8f8;
        position: relative;
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
        box-sizing: border-box;

        &:before {
            position: absolute;
            top: 14px;
            left: -12px;
            color: #fff;
            content: "!";
            width: 20px;
            height: 20px;
            border-radius: 100%;
            text-align: center;
            line-height: 20px;
            font-weight: bold;
            font-family: 'Dosis', 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;
            font-size: 14px;
        }

        &.info {
            border-left: 4px solid $light-green;
        }

        &.info:before {
            background-color: $light-green;
        }

        &.danger {
            border-left: 4px solid $red;
        }

        &.danger:before {
            background-color: $red;
        }

        p {
            margin: 0;
        }
    }

    time {
        white-space: nowrap;
    }
}

.post-content {
    ol,
    ul {
        padding-left: 3rem;
    }

    ol ol,
    ul ul,
    ul ol,
    ol ul {
        margin: 0 0 0.4em 0;
        padding-left: 2em;
    }

    ol p,
    ul p {
        width: 100%;
        margin-bottom: auto;
    }

    span[title] {
      border-bottom: thin dashed;
    }
}

.ds-thread, #disqus_thread {
    width: 90%;
    max-width: 700px;
    margin: auto;
}

.fluid-width-video-wrapper {
    max-width: 750px;
    margin: auto;
    padding: 0;
}

.bg-scroll {
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    height: 500px;
    margin: 0 0 1.75em 0;
}