
/* Clears shit */
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after { clear: both; }
.clearfix { zoom: 1; }

/* Hides shit */
.hidden {
    text-indent: -9999px;
    visibility: hidden;
    display: none;
}
