@media only screen and (max-width: 1080px) {
    .single-post {
        figcaption {
            position: static;
            width: auto;
            text-align: center;

            &::before {
                display: none; /* bugfix, base theme hides it too late (max-width: 780px)  */
            }
        }
    }

    .go-to-top {
        transform: translateX(0);
        right: 50px;
        left: auto;
    }
}

@media only screen and (min-width: 1081px) {
    .single-post .post-content > blockquote {
        max-width: 730px;

        p {
            margin-left: 10px;
            margin-right: 40px;
        }
    }
}
