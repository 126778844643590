
@media only screen and (max-width: 780px) {

    blockquote {
        margin-left: 0;
    }

    hr {
        margin: 2.4em 0;
    }

    ol, ul {
        padding-left: 2em;
    }

    h1 {
        font-size: 4.5rem;
        text-indent: -2px;
    }

    h2 {
        font-size: 3.6rem;
    }

    h3 {
        font-size: 3.1rem;
    }

    h4 {
        font-size: 2.5rem;
    }

    h5 {
        font-size: 2.2rem;
    }

    h6 {
        font-size: 1.8rem;
    }

    .single-post {
        figcaption {
            position: static;
            width: auto;
            text-align: center;
            &:before {
                display: none;
            }
        }
    }

    .go-to-top {
        right: 35px;
    }

    .category {
        .date {
            display: none;
        }

        .article {
            text-align: center;
        }
    }

    .bg-scroll {
        height: 350px;
    }
}