// header
.main-header {
    margin-bottom: 40px;
    padding-top: 10rem;
    background-size: cover;
    background-position: center;

    &.has-cover {
        .page-title,
        .page-title a,
        .page-description,
        .nav a {
            color: $color-white;
            text-shadow: 1px 1px 5px rgba($color-black, 0.5);
        }

        .nav {
            &:before, &:after {
                background: linear-gradient(to right, rgba($color-white, 0) 0%,rgba($color-white, 1) 50%,rgba($color-white, 0) 100%);
            }
        }
    }

    .nav,
    .page-title,
    .page-description {
        width: 90%;
        max-width: 500px;
        margin: auto;
    }

    .nav {
        margin: 40px auto 50px;
        padding-bottom: 20px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        li {
            min-width: 80px;
            margin: 5px;
            display: inline-block;
            font-size:  16px;
            text-transform: uppercase;
        }
    }

    .page-title {
        margin: 0 auto 15px;
        a {
            font-size: 70px;
            text-decoration: none;
            text-shadow: 1px 1px 5px rgba($color-black, 0.3);
        }
    }

    .page-description {
        font-size: 18px;
        font-weight: normal;
        font-style: italic;
        font-family: 'Merriweather', 'Microsoft YaHei', 'WenQuanYi Micro Hei', sans-serif;
        color: rgba($color-text-header, 0.8);
    }
}

.nav:before,
.nav:after,
.site-footer:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, rgba($color-black,0) 0%,rgba($color-black,1) 50%,rgba(0,0,0,0) 100%);
}

// post list
.post-title {
    font-size: 3.6rem;
}

.post-excerpt, .post-content {
    text-align: left;
}

.post-meta {
    margin-bottom: 20px;
    font-style: italic;
    a {
        text-decoration: underline;
        font-size: 15px;
        color: #666666;
    }
}

.post-excerpt {
    p {
        text-indent: 2em;
        margin-bottom: 10px;
    }

    .read-more {
        font-style: italic;
        text-decoration: underline;
    }
}

.content {
    .short-diver {
        position: relative;
        margin: 20px auto 40px;

        &:after {
            content: '';
            display: block;
            width: 40px;
            height: 4px;
            background: #eeeeee;
            margin: 60px auto 0;
        }
    }
}

// pagination
.pagination {
    font-size: 15px;
    margin-top: 20px;
    position: relative;
}

.newer-posts, .older-posts {
    position: absolute;
    top: 0;
    left: 0;
}

.older-posts {
    left: auto;
    right: 0;
}


.site-footer {
    max-width: 500px;
    width: 90%;
    padding: 20px 0;
    font-size: 12px;
    line-height: 1.3;
}

.site-footer p {
    margin: 0;
}

.site-footer:before {
    margin-bottom: 20px;
}


// make the content center and given a width
.post-list .post,
.pagination,
.site-footer,
.single-post .post-content > h1,
.single-post .post-content > h2,
.single-post .post-content > h3,
.single-post .post-content > h4,
.single-post .post-content > h5,
.single-post .post-content > h6,
.single-post .post-content > p,
.single-post .post-content > ul,
.single-post .post-content > ol,
.single-post .post-content > blockquote,
.single-post .post-content .single-post-header,
.single-post .post-content > table,
.single-post .post-content .table-nostyle > table,
.single-post .post-content .hint,
.single-post .post-content > address,
.single-post .post-content > article,
.single-post .post-content > aside,
.single-post .post-content > canvas,
.single-post .post-content > dd,
.single-post .post-content > dl,
.single-post .post-content > fieldset,
.single-post .post-content > figcaption,
.single-post .post-content > figure,
.single-post .post-content > footer,
.single-post .post-content > form,
.single-post .post-content > header,
.single-post .post-content > hgroup,
.single-post .post-content > li,
.single-post .post-content > main,
.single-post .post-content > nav,
.single-post .post-content > noscript,
.single-post .post-content > output,
.single-post .post-content > section,
.single-post .post-content > tfoot,
.single-post .post-content > video,
.single-post .post-content > iframe,
.read-next {
    width: 90%;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    word-wrap: break-word;
}