/* as long as is is not fixed in the forked repo */
.single-post .post-content {
    iframe {
        min-height: 350px;
    }
}

ol {
    width: 90%;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    word-wrap: break-word;
}

pre, pre[class*="language-"] {
    margin-bottom: 1.8em!important; /* override prism.js css */
}