@media only screen and (max-width: 500px) {

    hr {
        margin: 1.75em 0;
    }

    p, ul, ol, dl {
        font-size: 0.95em;
        margin: 0 0 2.5rem 0;
    }

    h1, h2, h3,
    h4, h5, h6 {
        margin: 0 0 0.3em 0;
    }

    h1 {
        font-size: 2.8rem;
        letter-spacing: -1px;
    }

    h2 {
        font-size: 2.4rem;
        letter-spacing: 0;
    }

    h3 {
        font-size: 2.1rem;
    }

    h4 {
        font-size: 1.9rem;
    }

    h5 {
        font-size: 1.8rem;
    }

    h6 {
        font-size: 1.8rem;
    }

    body:not(.post-template) .post-title {
        font-size: 2.5rem;
    }

    .read-next a {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding-right: 0;
        padding-left: 0;
    }

    .read-next a h2 {
        font-size: 2rem;
    }

    .single-post .post-content{
        img[src*='#right'], img[src*='#left'] {
            margin: 0;
            float: none;
            max-width: 100%;
            max-height: initial;
            height: auto;
        }

        figure {
            &.left, &.right, &.large {
                margin: 0 0 10px 0;
                width: 100%;
            }
        }
    }

    .go-to-top {
        right: 10px;
        display: none !important;
    }
}
