﻿@font-face {
    font-family: 'social';
    src: url('../fonts/social.woff2') format('woff2'), url('../fonts/social.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

[class^="social-"], [class*=" social-"] {
    white-space: nowrap;

    &:hover {
        text-decoration: none;
    }
}

[class^="social-"]:before, [class*=" social-"]:before {
    font-family: "social";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.social-mail-alt:before {
    content: '\f0e0';
}

.social-github-circled:before {
    content: '\f09b';
}

.social-twitter:before {
    content: '\f099';
}

.social-key:before {
    content: '\f084';
}

.social-mastodon:before {
    content: '\f2e2';
}
